import { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";

function SudokuPuzzle(props: { setNextLevel: Function }) {
    var [removedVals, startingBoard] = createSudoku();
    const [arrRemoved] = useState(removedVals);
    const [arrayStates, setStates] = useState(startingBoard);
    const validateResult = (event: React.ChangeEvent<HTMLInputElement>, a: number, b: number) => {
        var statesCopy = [...arrayStates];
        var numberInput = Number(event.currentTarget.value);
        if (isNaN(numberInput)) {
            return;
        }

        statesCopy[a][b] = numberInput % 10;
        setStates(statesCopy);
        for (var x = 0; x < arrRemoved.length; x++) {
            var removed = arrRemoved[x];
            if (removed.val !== statesCopy[removed.x][removed.y]) {
                return;
            }
        }

        props.setNextLevel();
    };

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col>
                        <table className="tb-sudoku">
                            <tbody>
                                {arrayStates.map((items, index) => {
                                    return (
                                        <tr key={"parent-" + index}>
                                            {items.map((subItems, sIndex) => {
                                                if (arrRemoved.find(element => element.x === index && element.y === sIndex)) {

                                                    return <td key={"item-" + index + "-" + sIndex}>
                                                        <input id={"input-" + index + "-" + sIndex} type="text"
                                                            value={subItems === 0 ? "" : subItems}
                                                            onChange={(e) => { validateResult(e, index, sIndex) }} />
                                                    </td>;
                                                }

                                                return <td key={"item-" + index + "-" + sIndex}>{subItems}</td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

var counter = 0;
const dim = 9;
const sudokuBoard = Array.from({ length: dim }, () => Array.from({ length: dim }, () => 0));
const numArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];

function createSudoku(): [removedVals: { x: number, y: number, val: number }[], startingBoard: number[][], solvedBoard: number[][]] {
    return newStartingBoard(30);
}

function shuffle(array : number[]) {
    let newArray = [...array]
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
}

const rowSafe = (puzzleArray: number[][], emptyCell: { x: number, y: number }, num: number) => {
    return puzzleArray[emptyCell.x].indexOf(num) === -1;
}

const colSafe = (puzzleArray: number[][], emptyCell: { x: number, y: number }, num: number) => {
    return !puzzleArray.some(row => row[emptyCell.y] === num);
}

const boxSafe = (puzzleArray: number[][], emptyCell: { x: number, y: number }, num: number) => {
    var boxStartRow = emptyCell.x - (emptyCell.x % 3);
    var boxStartCol = emptyCell.y - (emptyCell.y % 3);
    let safe = true;
    for (var boxRow of [0, 1, 2]) { 
        for (var boxCol of [0, 1, 2]) { 
            if (puzzleArray[boxStartRow + boxRow][boxStartCol + boxCol] === num) { 
                safe = false;
            }
        }
    }
    return safe
}

const safeToPlace = (puzzleArray: number[][], emptyCell: { x: number, y: number }, num: number) => {
    return rowSafe(puzzleArray, emptyCell, num) &&
        colSafe(puzzleArray, emptyCell, num) &&
        boxSafe(puzzleArray, emptyCell, num)
}

const nextEmptyCell = (puzzleArray: number[][]) => {
    const emptyCell = { x: -1, y: -1 };

    puzzleArray.forEach((row, rowIndex) => {
        if (emptyCell.y !== -1) return;
        let firstZero = row.find(col => col === 0);
        if (firstZero === undefined) return;
        emptyCell.x = rowIndex;
        emptyCell.y = row.indexOf(firstZero);
    })

    if (emptyCell.y !== -1) return emptyCell;
    return false;
}

const fillPuzzle = (startingBoard : number[][]) => {
    const emptyCell = nextEmptyCell(startingBoard)
    if (!emptyCell) return startingBoard;

    for (var num of shuffle(numArray)) {
        counter++;
        if (counter > 20_000_000) {
            throw new Error("Recursion Timeout");
        }

        if (safeToPlace(startingBoard, emptyCell, num)) {
            startingBoard[emptyCell.x][emptyCell.y] = num;
            if (fillPuzzle(startingBoard)) {
                return startingBoard;
            }

            startingBoard[emptyCell.x][emptyCell.y] = 0
        }
    }

    return false;
}

const newSolvedBoard = () => {
    const newBoard = sudokuBoard.map(row => row.slice());
    fillPuzzle(newBoard);
    return newBoard
}

const pokeHoles = (startingBoard: number[][], holes: number): [removedVals: { x: number, y: number, val: number }[], startingBoard: number[][]] => {
    const removedVals = [];
    const val = shuffle(range(0, 80));

    while (removedVals.length < holes) {
        const nextVal = val.pop();
        if (nextVal === undefined) {
            throw new Error("Impossible Game");
        }

        const randomRowIndex = Math.floor(nextVal / 9);
        const randomColIndex = nextVal % 9;
        var currentValue = startingBoard[randomRowIndex][randomColIndex];
        if (!startingBoard[randomRowIndex]) continue;
        if (currentValue === 0) continue;

        removedVals.push({
            x: randomRowIndex,
            y: randomColIndex,
            val: currentValue
        });

        startingBoard[randomRowIndex][randomColIndex] = 0;
        const proposedBoard = startingBoard.map(row => row.slice());
        if (multiplePossibleSolutions(proposedBoard)) {
            removedVals.pop();
            startingBoard[randomRowIndex][randomColIndex] = currentValue;
        }

    }
    return [removedVals, startingBoard]
};

function newStartingBoard(holes: number): [ removedVals: { x: number, y: number, val: number }[], startingBoard: number[][], solvedBoard: number[][] ] {
    try {
        counter = 0
        let solvedBoard = newSolvedBoard();
        var [removedVals, startingBoard] = pokeHoles(solvedBoard.map(row => row.slice()), holes);
        return [ removedVals, startingBoard, solvedBoard ];

    } catch (error) {

    }

    return newStartingBoard(holes);
}

function multiplePossibleSolutions(boardToCheck: number[][]) {
    const possibleSolutions = []
    const emptyCellArray = emptyCellCoords(boardToCheck)
    for (let index = 0; index < emptyCellArray.length; index++) {
        var emptyCellClone = [...emptyCellArray]
        const startingPoint = emptyCellClone.splice(index, 1);
        emptyCellClone.unshift(startingPoint[0])
        var thisSolution = fillFromArray(boardToCheck.map(row => row.slice()), emptyCellClone, 0);
        if (thisSolution !== false) {
            possibleSolutions.push(thisSolution.join());
        }

        if (Array.from(new Set(possibleSolutions)).length > 1) {
            return true;
        }
    }

    return false;
}

function fillFromArray(startingBoard: number[][], emptyCellArray: { x: number, y: number }[], pokeCounter: number) {
    const emptyCell = nextStillEmptyCell(startingBoard, emptyCellArray)
    if (!emptyCell) return startingBoard
    for (var num of shuffle(numArray)) {
        pokeCounter++;
        if (pokeCounter > 60_000_000) {
            throw new Error("Poke Timeout");
        }

        if (safeToPlace(startingBoard, emptyCell, num)) {
            startingBoard[emptyCell.x][emptyCell.y] = num
            if (fillFromArray(startingBoard, emptyCellArray, pokeCounter)) {
                return startingBoard;
            }

            startingBoard[emptyCell.x][emptyCell.y] = 0
        }
    }

    return false;
}

function nextStillEmptyCell(startingBoard: number[][], emptyCellArray: {x:number, y:number}[]) {
    for (var coords of emptyCellArray) {
        if (startingBoard[coords.x][coords.y] === 0) {
            return { x: coords.x, y: coords.y };
        }
    }

    return false;
}

const range = (start: number, end: number) => {
    const length = end - start + 1
    return Array.from({ length }, (_, i) => start + i)
}

function emptyCellCoords(startingBoard: number[][]) {
    const listOfEmptyCells = []
    for (const x of range(0, 8)) {
        for (const y of range(0, 8)) {
            if (startingBoard[x][y] === 0) listOfEmptyCells.push({ x, y })
        }
    }

    return listOfEmptyCells;
}

export default SudokuPuzzle;
