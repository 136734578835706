import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLevelGet } from "../hooks/LevelService";
import useStorageForLevel from "../hooks/Storage";
import TipsLevel from "../level/TipsLevel";
import YouTubeSubscribe from "./YoutubeSubscribe";
import Button from 'react-bootstrap/Button';

function Guide() {
    const dictLevel = useLevelGet();
    const [storedLevel] = useStorageForLevel();
    const navigate = useNavigate();
    return (
        <Container fluid className="main-container">
            <Row>
                <Col md={{ span: 8, offset: 2 }}>
                    <h1>To The Next Level [TTNL]</h1>
                    <i>Levels : {Object.keys(dictLevel).length} {storedLevel > 1 ? "(" + (storedLevel - 1) + " completed)" : ""}</i><br />
                </Col>
            </Row>
            <Row className="m-4">
                <Col className="d-grid gap-2  col-8 offset-2">
                    <Button variant="primary" size="lg" onClick={() => navigate("/level/" + storedLevel)}>{storedLevel > 1 ? "Resume" : "Start"}</Button>
                    {storedLevel > 1 && <Button variant="secondary" size="lg" onClick={() => navigate('/level/1')}>Restart</Button>}
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 8, offset: 2 }}>
                    <h2>Tips</h2>
                    <p>
                        The enigma is displayed in the middle of the screen in white.<br />
                        Clues are hidden behind the "?". Click to reveal them if needed.<br />
                        To solve the enigma, you need to analyze the different components.<br />
                        Colors, text, letters, symbols are all you need to solve it.<br />
                        But, if you are stuck, reveal the clues to progress.<br />
                        Don't be afraid to look at the solution on youtube if you find it too hard!
                    </p>
                    <h2>Example</h2>
                    <TipsLevel mainTip={"This is the enigma."} tips={["clue"]} />
                    <h2>Social</h2>
                    <YouTubeSubscribe
                        channelid="UCGQoy8AU0MXj54iLRT7odsw"
                        theme={"default"}
                        layout={"full"}
                        count={"default"}
                    />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={{ span: 8, offset: 2 }}>
                    <h2>Why play?</h2>
                    <h3>Increase cognitive ability</h3>
                    <p>
                        The objective of this game is to bring you to the next level!
                        Practicing solving enigma regularly increase your cognitive capacity.
                        Even, if you cannot find the solution of the riddle, your process of thoughts will change with time.
                        The solution will be post weekly on youtube if you need them.
                    </p>
                    <h3>Learn new subjects</h3>
                    <p>

                        There is multiple categories of enigma you will find on this game.
                        All of them have their own logic and connections.
                        This will also increase your culture and make you discover knowledge.
                        History, Mathematics, Chemistry, Physics, Biology and Technology are the main subjects.
                        But, new subjects will be introduce with new levels in the future.
                    </p>
                    <h3>Feel good</h3>
                    <p>
                        I said it like I mean it!
                        You will be proud after solving new riddles.
                        Most of enigmas are not easy to solve.
                        That's why solving them always give you a feeling of accomplishment!
                        And, you will have a funny gif after each completion.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default Guide;
