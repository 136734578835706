import { Col, Container, Row } from "react-bootstrap";
import LevelData from "./LevelData";
import LockerLevel from "./LockerLevel";
import TipsLevel from "./TipsLevel";

function DefaultLevel(props: { levelData: LevelData, currentLevel: number, setNextLevel: (set?: boolean) => void }) {
    return (
        <div>
            <Container fluid >
                <Row>
                    <Col>
                        <div className="level-center">LEVEL {props.currentLevel}</div>
                        <TipsLevel mainTip={props.levelData.mainTip} tips={props.levelData.tips} />
                        <LockerLevel displayText={props.levelData.displayText} level={props.currentLevel} nbCharacters={props.levelData.nbCharacters} setNextLevel={props.setNextLevel} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DefaultLevel;
