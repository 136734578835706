import React from 'react';
import { Container} from 'react-bootstrap';

class Locked extends React.Component<{ currentLevel: number }> {
    render() {
        return (
            <div>
                <Container fluid >
                    <div className="level-no-access">Level {this.props.currentLevel} is not accessible yet!</div>
                </Container>
            </div>
        );
    }
}

export default Locked;
