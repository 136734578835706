import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { getRandomNumber } from '../hooks/RandomGenerator';
import PuzzleType from './PuzzleType';


function SelectTypePuzzle(props: { setSelectedTypes: Function }) {
    const [currentSelectedTypes, setCurrentSelectedTypes] = useState(new Array<number>());
    const addOrDeleteTypes = (key: number) => {
        var copy = [...currentSelectedTypes];
        if (copy.includes(key)) {
            copy.splice(copy.indexOf(key), 1);
        }
        else {
            copy.push(key);
        }

        setCurrentSelectedTypes(copy);
    };
    const pushSelectedTypes = () => {
        var generatedRandom = new Array<number>();
        for (var i = 0; i < 10; i++) {
            generatedRandom.push(currentSelectedTypes[getRandomNumber(0, currentSelectedTypes.length)]);
        }

        props.setSelectedTypes(generatedRandom);
    };
    return <>
        <Container fluid className="main-container">
            <Row className="m-4">
                <Col>
                    <p className="text-center">Please select the puzzle categories you want to play.</p>
                </Col>
            </Row>
            <Row className="mt-4">
            {Object.keys(PuzzleType).map(x => parseInt(x)).filter(x => !isNaN(x) && x > 0).map((key: number) => {
                return <Col xs={12} sm={6} lg={2} key={"puzzle-" + key}><div className={(currentSelectedTypes.includes(key) ? "selected " : "") + "puzzle"} onClick={() => addOrDeleteTypes(key)}>{PuzzleType[key]}</div></Col>
            })}
            </Row>
            <Row className="m-4">
                <Col className="d-grid gap-2  col-8 offset-2">
                    <Button variant="primary" size="lg" disabled={currentSelectedTypes.length <= 0} onClick={() => currentSelectedTypes.length > 0 && pushSelectedTypes()}>Start</Button>
                </Col>
            </Row>
        </Container>
    </>;
}


export default SelectTypePuzzle;