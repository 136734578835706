import { useState } from "react";

function useStorageForLevel() {
    var item = localStorage.getItem("TTLN_Level_1");
    var currentLevel = item ? parseInt(item) : 1;
    const [level, setLevel] = useState(currentLevel);
    const saveLevel = (value: number | null, set?: boolean | true) => {
        value = value || 1;
        if (currentLevel < value) {
            localStorage.setItem("TTLN_Level_1", value.toString());
        }

        if (set === undefined || set) {
            setLevel(value);
        }
    };

    return [level, saveLevel] as const;
}

export default useStorageForLevel;