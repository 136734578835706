export function randomGenerator(dim: number): number[][] {
    var arrRandom = Array.from({ length: dim }, () => Array.from({ length: dim }, () => 1));
    for (var i = 0; i < 20; i++) {
        arrRandom = permutation(arrRandom, Math.floor(Math.random() * 10) % dim, Math.floor(Math.random() * 10) % dim);
    }

    return arrRandom;
}

export function permutation(arrayValues: number[][], x: number, y: number): number[][] {
    let copy = [...arrayValues];
    copy[x][y] = copy[x][y] === 0 ? 1 : 0;
    if (x >= 1) {
        copy[x - 1][y] = copy[x - 1][y] === 0 ? 1 : 0;
    }
    if (x < copy.length - 1) {
        copy[x + 1][y] = copy[x + 1][y] === 0 ? 1 : 0;
    }
    if (y >= 1) {
        copy[x][y - 1] = copy[x][y - 1] === 0 ? 1 : 0;
    }
    if (y < copy.length - 1) {
        copy[x][y + 1] = copy[x][y + 1] === 0 ? 1 : 0;
    }

    return copy;
};

export function getRandomEnumMember<T extends object>(anEnum: T) {
    const enumValues = Object.keys(anEnum)
        .map(n => Number.parseInt(n))
        .filter(n => !Number.isNaN(n)) as unknown as T[keyof T][];
    const randomIndex = getRandomNumber(1, enumValues.length);
    const randomEnumValue = enumValues[randomIndex];
    return randomEnumValue;
}

export function getRandomNumber(index: number, nbElements: number) {
    return Math.floor(Math.random() * 10) % (nbElements - index) + index;
}