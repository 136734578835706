export enum PuzzleType {
    None = 0,
    Permutation = 1,
    Memory = 2,
    Nonogram = 3,
    Sudoku = 4,
    Shuffle = 5,
    //Crossword = 6
};

export default PuzzleType;