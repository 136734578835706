import { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { randomGenerator } from '../hooks/RandomGenerator';

function NonogramPuzzle(props: { setNextLevel: Function }) {
    const getRowsCount = (arr: number[][]): JSX.Element[][] => {
        var result = Array.from({ length: arr.length }, () => { var arr: JSX.Element[] = []; return arr; });
        for (var x = 0; x < arr.length; x++) {
            var count = 0;
            for (var y = 0; y < arr.length; y++) {
                if (arr[x][y] === 0 && count !== 0) {
                    result[x].push(<span key={"r-" + x + "-" + y}>{count}</span>);
                    count = 0;
                }
                else if (arr[x][y] !== 0) {
                    ++count;
                }
            }

            if (count !== 0) {
                result[x].push(<span key={"r-" + x + "-" + arr.length}>{count}</span>);
            }
        }

        return result;
    };
    const getColumnsCount = (arr: number[][]): JSX.Element[][] => {
        var result = Array.from({ length: arr.length }, () => { var arr: JSX.Element[] = []; return arr; });
        for (var x = 0; x < arr.length; x++) {
            var count = 0;
            for (var y = 0; y < arr.length; y++) {
                if (arr[y][x] === 0 && count !== 0) {
                    result[x].push(<div key={"c-" + y + "-" + x}>{count}</div>);
                    count = 0;
                }
                else if (arr[y][x] !== 0) {
                    ++count;
                }
            }

            if (count !== 0) {
                result[x].push(<div key={"c-" + arr.length + "-" + x}>{count}</div>);
            }
        }

        return result;
    };
    const [arrRandom] = useState(randomGenerator(6));
    const [rowsCount] = useState(getRowsCount(arrRandom));
    const [columnsCount] = useState(getColumnsCount(arrRandom));
    const [arrayStates, setStates] = useState(Array.from({ length: arrRandom.length }, () => Array.from({ length: arrRandom.length }, () => 0)));
    const validateResult = (event: React.ChangeEvent, a: number, b: number) => {
        var statesCopy = [...arrayStates];
        statesCopy[a][b] = statesCopy[a][b] === 0 ? 1 : 0;
        setStates(statesCopy);
        for (var x = 0; x < arrRandom.length; x++) {
            for (var y = 0; y < arrRandom.length; y++) {
                if (statesCopy[x][y] !== arrRandom[x][y]) {
                    return;
                }
            }
        }

        props.setNextLevel();
    };

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col>
                        <table className="tb-nonogram">
                            <thead>
                                <tr>
                                    <th></th>
                                    {columnsCount.map((items, index) => {
                                        return (
                                            <th key={"head-" + index}>{items}</th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {arrayStates.map((items, index) => {
                                    return (
                                        <tr key={"parent-" + index}>
                                            <td>{rowsCount[index]}</td>
                                            {items.map((subItems, sIndex) => {
                                                return <td key={"item-" + index + "-" + sIndex}>
                                                    <input id={"checkbox-" + index + "-" + sIndex} type="checkbox"
                                                        className="css-checkbox"
                                                        checked={subItems === 1}
                                                        onChange={(e) => { validateResult(e, index, sIndex) }} />
                                                    <label htmlFor={"checkbox-" + index + "-" + sIndex}></label>
                                                </td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default NonogramPuzzle;
