import React from "react";

export default class YouTubeSubscribe extends React.Component<{ theme?: string, layout?: string, count?: string, channelName?: string, channelid?: string }, { initialized : boolean}> {
    youtubeSubscribeNode: React.RefObject<HTMLDivElement>;

    constructor(props : any) {
        super(props);
        this.youtubeSubscribeNode = React.createRef();

        this.state = {
            initialized: false
        };
    }

    componentDidMount() {
        if (this.state.initialized) {
            return;
        }

        const youtubescript = document.createElement("script");
        youtubescript.src = "https://apis.google.com/js/platform.js";
        this.youtubeSubscribeNode.current?.parentNode?.appendChild(youtubescript);
        this.setState({
            initialized: true
        });
    }

    render() {
        const { theme, layout, count, channelName, channelid } = this.props;

        return (
            <section className="youtubeSubscribe">
                <div
                    ref={this.youtubeSubscribeNode}
                    className="g-ytsubscribe"
                    data-theme={theme}
                    data-layout={layout}
                    data-count={count}
                    data-channel={channelName}
                    data-channelid={channelid}
                />
            </section>
        );
    }
}
