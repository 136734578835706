import React from "react";
import { useLevelCheck } from "../hooks/LevelService";

function LockerLevel(props: { displayText?: string, setNextLevel: Function, level: number, nbCharacters?: number }) {
    var [checkedStatus, fnSetValue] = useLevelCheck(props.level, "");
    const checkInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.nbCharacters !== undefined && event.target.value.length !== props.nbCharacters) {
            return;
        }

        fnSetValue(event.target.value);
    };

    if (checkedStatus) {
        props.setNextLevel();
    }
    return <div className="locker-center">
        <input className="fake-textbox" type="text"
            defaultValue={props.displayText || ""}
            onChange={checkInput}
            placeholder={props.nbCharacters !== undefined ? props.nbCharacters + " characters" : ""}
        />
    </div>;
}

export default LockerLevel;
