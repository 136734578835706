import { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { GiLightBulb, GiChameleonGlyph, GiSun, GiFluffyCloud } from 'react-icons/gi';
import { randomGenerator, permutation } from '../hooks/RandomGenerator';

function PermutationPuzzle(props: { setNextLevel : Function}) {
    const styleList = [
        <GiLightBulb className="big-box lightbulb" />,
        <GiChameleonGlyph className="big-box chameleon" />,
        <GiSun className="big-box sun" />,
        <GiFluffyCloud className="big-box cloud" />
    ];
    var indexStyle = Math.floor(Math.random() * 10) % styleList.length;
    var style = styleList[indexStyle];
    var arrRandom = randomGenerator(6);
    const [displayStyle] = useState(style);
    const [arrayValues, setArrayValues] = useState(arrRandom);
    const changeColor = (event: React.MouseEvent, x: number, y: number) => {
        event.preventDefault();
        var dimCopy = arrayValues.length;
        var copy = permutation(arrayValues, x, y);
        setArrayValues(copy);
        if (copy.reduce((r, x) => r + x.reduce((s, y) => s + y, 0), 0) === dimCopy * dimCopy) {
            props.setNextLevel();
        }
    };

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col>
                        <table className="m-auto">
                            <tbody>
                            {arrayValues.map((items, index) => {
                                return (
                                    <tr key={"parent-" + index}>
                                        {items.map((subItems, sIndex) => {
                                            return <td key={"item-" + index + "-" + sIndex}><div className={"item-" + subItems} onClick={(e) => { changeColor(e, index, sIndex) }}>{displayStyle}</div></td>;
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default PermutationPuzzle;
