import React from "react";

function TipsLevel(props: { mainTip: string, tips: string[] }) {
    return <div className="tips-center">
        <div className="main-tip" dangerouslySetInnerHTML={{ __html: props.mainTip }}></div>
        {
            props.tips.map((item, i) => {
                return <div className="tip-box hide-tip" key={"item-" + i} onClick={displayTip}><div className={"img-tip " + item}></div></div>;
            })
        }
    </div>;
}

function displayTip(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    event.currentTarget.className = "tip-box";
}

export default TipsLevel;
