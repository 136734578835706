import { useEffect, useState } from "react";
import LevelData from "../level/LevelData";

const url = "https://api.ttnl.org/level/";
export function useLevelGet(): {[id: string]: LevelData} {
    const [dictLevel, setLevels] = useState({ 0: {nbCharacters: 0, mainTip: "", tips: [], displayText: ""} });
    useEffect(() => {
        fetch(process.env.NODE_ENV !== "development" ? url : "http://localhost:3000/mock/response-levelget.json")
            .then(x => x.json())
            .then(x => setLevels(x.result));
    }, []);

    return dictLevel;
}

export function useLevelCheck(level: number, value: string) : [checked: boolean, setValue: Function] {
    const [responseChecked, setChecked] = useState(false);
    const [stringToCheck, setStringToCheck] = useState(value);
    useEffect(() => {
        if (stringToCheck !== "") {
            fetch(process.env.NODE_ENV !== "development" ? url + level + '/check/' + encodeURIComponent(stringToCheck) : "http://localhost:3000/mock/response-levelcheck.json")
                .then(x => x.json())
                .then(x => setChecked(Boolean(x.result ?? false)));
        }
    }, [stringToCheck, level]);

    return [responseChecked, setStringToCheck];
}