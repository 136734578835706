import { Container} from 'react-bootstrap';

function Congrats(props: any) {
    var lstImages = [
        "dance-party.gif",
        "sacha-baron.gif",
        "dance-dancing.gif",
        "meong-cat.gif",
        "celebration-will-smith.gif",
        "party-popper-confetti.gif",
        "bt21-koya.gif",
        "minions-its-time-to-party.gif",
        "oh-yay.gif",
        "lit-baby.gif",
        "celebration-yay.gif",
        "dance-chandler.gif",
        "gjaja-dj.gif",
        "funny-handsign.gif",
        "pikachu-pride.gif",
        "youre-a-winner-winner.gif",
        "success-kid-hells-yes.gif"
    ];

    var r = props.currentLevel % lstImages.length;
    return (
        <div>
            <Container fluid >
                <div className="congrats-center">
                    Congratulations on completing level {props.currentLevel}!
                </div>
                <div className="congrats-center">
                    <img src={"../images/" + lstImages[r]} alt="congrats" width="250" />
                </div>
            </Container>
        </div>
    );
}

export default Congrats;
