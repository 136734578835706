import { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function generateRandomWord() {
    const arrWords = [
        "ABSOLUTE",
        "ACADEMIC",
        "ACCIDENT",
        "ACCURATE",
        "ACTIVIST",
        "ACTIVITY",
        "ACTUALLY",
        "ADDITION",
        "ADEQUATE",
        "ADVANCED",
        "ADVOCATE",
        "AIRCRAFT",
        "ALLIANCE",
        "ALTHOUGH",
        "ANALYSIS",
        "ANNOUNCE",
        "ANYTHING",
        "ANYWHERE",
        "APPARENT",
        "APPROACH",
        "APPROVAL",
        "ARGUMENT",
        "ARTISTIC",
        "ATHLETIC",
        "ATTITUDE",
        "AUDIENCE",
        "BATHROOM",
        "BEHAVIOR",
        "BIRTHDAY",
        "BOUNDARY",
        "BUILDING",
        "BUSINESS",
        "CAMPAIGN",
        "CAPACITY",
        "CATEGORY",
        "CEREMONY",
        "CHAMPION",
        "CHANGING",
        "CIVILIAN",
        "CLINICAL",
        "CLOTHING",
        "COLLAPSE",
        "COMPLAIN",
        "COMPLETE",
        "COMPRISE",
        "COMPUTER",
        "CONCLUDE",
        "CONCRETE",
        "CONFLICT",
        "CONFRONT",
        "CONFUSED",
        "CONGRESS",
        "CONSIDER",
        "CONSTANT",
        "CONSUMER",
        "CONTINUE",
        "CONTRACT",
        "CONTRARY",
        "CONTRAST",
        "CONVINCE",
        "CORRIDOR",
        "COVERAGE",
        "COVERING",
        "CREATION",
        "CREATIVE",
        "CREATURE",
        "CRIMINAL",
        "CRITERIA",
        "CRITICAL",
        "CROSSING",
        "CULTURAL",
        "CURRENCY",
        "CUSTOMER",
        "DARKNESS",
        "DATABASE",
        "DAUGHTER",
        "DAYLIGHT",
        "DEADLINE",
        "DECIDING",
        "DECISION",
        "DECREASE",
        "DEFERRED",
        "DEFINITE",
        "DELICATE",
        "DELIVERY",
        "DESCRIBE",
        "DESIGNER",
        "DETAILED",
        "DIABETES",
        "DIALOGUE",
        "DIAMETER",
        "DIRECTLY",
        "DIRECTOR",
        "DISABLED",
        "DISAGREE",
        "DISASTER",
        "DISCLOSE",
        "DISCOUNT",
        "DISCOVER",
        "DISORDER",
        "DISPOSAL",
        "DISTANCE",
        "DISTINCT",
        "DISTRICT",
        "DIVIDEND",
        "DIVISION",
        "DOCTRINE",
        "DOCUMENT",
        "DOMESTIC",
        "DOMINANT",
        "DOMINATE",
        "DOUBTFUL",
        "DRAMATIC",
        "DRESSING",
        "DROPPING",
        "DURATION",
        "DYNAMICS",
        "EARNINGS",
        "ECONOMIC",
        "EDUCATED",
        "EDUCATOR",
        "EFFICACY",
        "EIGHTEEN",
        "ELECTION",
        "ELECTRIC",
        "ELIGIBLE",
        "EMERGING",
        "EMISSION",
        "EMPHASIS",
        "EMPLOYEE",
        "EMPLOYER",
        "ENDEAVOR",
        "ENGAGING",
        "ENGINEER",
        "ENORMOUS",
        "ENTIRELY",
        "ENTRANCE",
        "ENVELOPE",
        "EQUALITY",
        "EQUATION",
        "ESTIMATE",
        "EVALUATE",
        "EVENTUAL",
        "EVERYDAY",
        "EVERYONE",
        "EVIDENCE",
        "EXCHANGE",
        "EXCITING",
        "EXERCISE",
        "EXISTING",
        "EXPLICIT",
        "EXPOSURE",
        "EXTENDED",
        "EXTERNAL",
        "FACILITY",
        "FAMILIAR",
        "FAVORITE",
        "FEATURED",
        "FEEDBACK",
        "FESTIVAL",
        "FIGHTING",
        "FINISHED",
        "FIREWALL",
        "FLAGSHIP",
        "FLEXIBLE",
        "FLOATING",
        "FOOTBALL",
        "FOOTHILL",
        "FORECAST",
        "FOREMOST",
        "FORMERLY",
        "FOURTEEN",
        "FRACTION",
        "FRANKLIN",
        "FREQUENT",
        "FRIENDLY",
        "FRONTIER",
        "FUNCTION",
        "GENERATE",
        "GENEROUS",
        "GENOMICS",
        "GOODWILL",
        "GOVERNOR",
        "GRADUATE",
        "GRAPHICS",
        "GRATEFUL",
        "GREATEST",
        "GUARDIAN",
        "GUIDANCE",
        "HANDLING",
        "HARDWARE",
        "HEADLINE",
        "HERITAGE",
        "HIGHLAND",
        "HISTORIC",
        "HOMELESS",
        "HOMEPAGE",
        "HOSPITAL",
        "HUMANITY",
        "IDENTIFY",
        "IDENTITY",
        "IDEOLOGY",
        "IMPERIAL",
        "INCIDENT",
        "INCLUDED",
        "INCREASE",
        "INDICATE",
        "INDIRECT",
        "INDUSTRY",
        "INFORMAL",
        "INFORMED",
        "INHERENT",
        "INITIATE",
        "INNOCENT",
        "INSPIRED",
        "INSTANCE",
        "INTEGRAL",
        "INTENDED",
        "INTERACT",
        "INTEREST",
        "INTERIOR",
        "INTERNAL",
        "INTERNET",
        "INTERVAL",
        "INTIMATE",
        "INTRANET",
        "INVASION",
        "INVESTOR",
        "INVOLVED",
        "ISOLATED",
        "JUDGMENT",
        "JUDICIAL",
        "JUNCTION",
        "KEYBOARD",
        "LANDLORD",
        "LANGUAGE",
        "LAUGHTER",
        "LEARNING",
        "LEVERAGE",
        "LIFETIME",
        "LIGHTING",
        "LIKEWISE",
        "LIMITING",
        "LITERARY",
        "LOCATION",
        "MAGAZINE",
        "MAGNETIC",
        "MAINTAIN",
        "MAJORITY",
        "MARGINAL",
        "MARRIAGE",
        "MATERIAL",
        "MATURITY",
        "MAXIMIZE",
        "MEANTIME",
        "MEASURED",
        "MEDICINE",
        "MEDIEVAL",
        "MEMORIAL",
        "MERCHANT",
        "MIDNIGHT",
        "MILITARY",
        "MINIMIZE",
        "MINISTER",
        "MINISTRY",
        "MINORITY",
        "MOBILITY",
        "MODELING",
        "MODERATE",
        "MOMENTUM",
        "MONETARY",
        "MOREOVER",
        "MORTGAGE",
        "MOUNTAIN",
        "MOUNTING",
        "MOVEMENT",
        "MULTIPLE",
        "MUSICIAN",
        "NATIONAL",
        "NEGATIVE",
        "NEIGHBOR",
        "NINETEEN",
        "NORMALLY",
        "NORTHERN",
        "NOTEBOOK",
        "NUMEROUS",
        "OBSERVER",
        "OCCASION",
        "OFFERING",
        "OFFICIAL",
        "OFFSHORE",
        "OPERATOR",
        "OPPONENT",
        "OPPOSITE",
        "OPTIMISM",
        "OPTIONAL",
        "ORDINARY",
        "ORGANIZE",
        "ORIGINAL",
        "OVERCOME",
        "OVERHEAD",
        "OVERLOOK",
        "OVERSEAS",
        "OVERVIEW",
        "PAINTING",
        "PARALLEL",
        "PARENTAL",
        "PATENTED",
        "PATIENCE",
        "PEACEFUL",
        "PERCEIVE",
        "PERIODIC",
        "PERSONAL",
        "PERSUADE",
        "PETITION",
        "PHYSICAL",
        "PIPELINE",
        "PLANNING",
        "PLATFORM",
        "PLEASANT",
        "PLEASURE",
        "POLITICS",
        "PORTABLE",
        "PORTRAIT",
        "POSITION",
        "POSITIVE",
        "POSSIBLE",
        "POSSIBLY",
        "POWERFUL",
        "PRACTICE",
        "PRECIOUS",
        "PREGNANT",
        "PRESENCE",
        "PRESERVE",
        "PRESSING",
        "PRESSURE",
        "PREVIOUS",
        "PRINCESS",
        "PRINTING",
        "PRIORITY",
        "PRISONER",
        "PROBABLE",
        "PROBABLY",
        "PRODUCER",
        "PROFOUND",
        "PROGRESS",
        "PROPERLY",
        "PROPERTY",
        "PROPOSAL",
        "PROPOSED",
        "PROSPECT",
        "PROTOCOL",
        "PROVIDED",
        "PROVIDER",
        "PROVINCE",
        "PUBLICLY",
        "PURCHASE",
        "PURSUANT",
        "QUANTITY",
        "QUESTION",
        "RATIONAL",
        "REACTION",
        "RECEIVED",
        "RECEIVER",
        "RECENTLY",
        "RECOVERY",
        "REGIONAL",
        "REGISTER",
        "REGULATE",
        "RELATION",
        "RELATIVE",
        "RELEVANT",
        "RELIABLE",
        "RELIANCE",
        "RELIGION",
        "REMEMBER",
        "RENOWNED",
        "REPEATED",
        "REPORTER",
        "REPUBLIC",
        "REQUIRED",
        "RESEARCH",
        "RESEMBLE",
        "RESERVED",
        "RESIDENT",
        "RESIGNED",
        "RESOURCE",
        "RESPONSE",
        "RESTRICT",
        "REVISION",
        "RIGOROUS",
        "ROMANTIC",
        "SAMPLING",
        "SANCTION",
        "SCENARIO",
        "SCHEDULE",
        "SCRUTINY",
        "SEASONAL",
        "SECONDLY",
        "SECURITY",
        "SENSIBLE",
        "SENTENCE",
        "SEPARATE",
        "SEQUENCE",
        "SERGEANT",
        "SHIPPING",
        "SHOOTING",
        "SHOPPING",
        "SHORTAGE",
        "SHOULDER",
        "SIMPLIFY",
        "SITUATED",
        "SLIGHTLY",
        "SOFTWARE",
        "SOLUTION",
        "SOMEBODY",
        "SOMEWHAT",
        "SOUTHERN",
        "SPEAKING",
        "SPECIFIC",
        "SPECTRUM",
        "SPENDING",
        "SPORTING",
        "STANDARD",
        "STANDING",
        "STANDOUT",
        "STERLING",
        "STRAIGHT",
        "STRANGER",
        "STRATEGY",
        "STRENGTH",
        "STRIKING",
        "STRONGLY",
        "STRUGGLE",
        "STUNNING",
        "SUBURBAN",
        "SUDDENLY",
        "SUITABLE",
        "SUPERIOR",
        "SUPPOSED",
        "SURGICAL",
        "SURPRISE",
        "SURROUND",
        "SURVIVAL",
        "SURVIVOR",
        "SWEEPING",
        "SWIMMING",
        "SYMBOLIC",
        "SYMPATHY",
        "SYNDROME",
        "TACTICAL",
        "TAILORED",
        "TAKEOVER",
        "TANGIBLE",
        "TAXATION",
        "TAXPAYER",
        "TEACHING",
        "TEASPOON",
        "TEENAGER",
        "TENDENCY",
        "TERMINAL",
        "TERRIBLE",
        "THINKING",
        "THIRTEEN",
        "THOROUGH",
        "THOUSAND",
        "THREATEN",
        "TOGETHER",
        "TOMORROW",
        "TOUCHING",
        "TRACKING",
        "TRAINING",
        "TRANSFER",
        "TRAVELED",
        "TREASURY",
        "TRIANGLE",
        "TROPICAL",
        "TURNOVER",
        "ULTIMATE",
        "UMBRELLA",
        "UNIVERSE",
        "UNLAWFUL",
        "UNLIKELY",
        "VACATION",
        "VALUABLE",
        "VARIABLE",
        "VERTICAL",
        "VICTORIA",
        "VIOLENCE",
        "VOLATILE",
        "WARRANTY",
        "WEAKNESS",
        "WEIGHTED",
        "WHATEVER",
        "WHENEVER",
        "WHEREVER",
        "WILDLIFE",
        "WIRELESS",
        "WITHDRAW",
        "WOODLAND",
        "WORKSHOP",
        "YOURSELF",
    ];
    var wordIndex = Math.floor(Math.random() * arrWords.length);
    return arrWords[wordIndex];
}

function shuffle(word: string) {
    var a = word.split(""),
        n = a.length;

    for (var i = n - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var tmp = a[i];
        a[i] = a[j];
        a[j] = tmp;
    }
    return a.join("");
}

function DragPuzzle(props: { setNextLevel: Function }) {
    var [wordToFind] = useState(generateRandomWord());
    var [currentWord, setCurrentWord] = useState(shuffle(wordToFind));
    const onDragEnd = (result: any) => {
        var arrLetters = Array.from(currentWord.split(""));
        var [removed] = arrLetters.splice(result.source.index, 1);
        arrLetters.splice(result.destination.index, 0, removed);
        var newWord = arrLetters.join("");
        setCurrentWord(newWord);
        if (newWord === wordToFind) {
            props.setNextLevel();
        }
    };

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col className="svg-container">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" direction={window.innerHeight < window.innerWidth ? "horizontal":"vertical"}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        className={snapshot.isDraggingOver ? "droppable draggingOver" : "droppable"}
                                        {...provided.droppableProps}
                                    >
                                        {currentWord.split("").map((item, index) => (
                                            <Draggable key={"item-" + index} draggableId={"item-" + index} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={snapshot.isDragging ? "svg-draggable dragging" : "svg-draggable"}
                                                    >
                                                        <svg className="svg-letters">
                                                            <circle r="calc(max(4vh, 4vw))"></circle>
                                                            <text>{item}</text>
                                                        </svg>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DragPuzzle;
