import { useParams } from "react-router-dom";
import useStorageForLevel from "../hooks/Storage";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLevelGet } from '../hooks/LevelService';

import '../css/level.css';
import Locked from "./Locked";
import Congrats from "./Congrats";
import DefaultLevel from "./DefaultLevel";
import LevelData from "./LevelData";
import eTransition from './eTransition';

function ActiveLevel() {
    const dictLevel = useLevelGet();
    const [isTransitioning, setTransition] = useState(eTransition.in);
    const [isCongrats, setCongrats] = useState(false);
    const navigate = useNavigate();
    const [storedLevel, setCurrentLevel] = useStorageForLevel();
    const navigateTTNL = (level: number, set?: boolean, transition?: boolean) => {
        if ((set === undefined || set) && (transition === undefined || transition)) {
            setTransition(eTransition.out);
            setTimeout(() => {
                setTransition(eTransition.in);
                setCongrats(true);
                setTimeout(() => {
                    setTransition(eTransition.out);
                    setTimeout(() => {
                        setCongrats(false);
                        setTransition(eTransition.in);
                        setCurrentLevel(level, set);
                        navigate('/level/' + level);
                    }, 2000);
                }, 4000);
            }, 2000);
        }
        else if (set === undefined || set) {
            setCurrentLevel(level, set);
            navigate('/level/' + level);
        }
        else {
            setCurrentLevel(level, set);
        }
    };
    const { levelId } = useParams();
    var level = levelId ? parseInt(levelId) : 0;
    return <div className="main-container">{getActiveLevel(storedLevel, navigateTTNL, isCongrats, isTransitioning, dictLevel, level)}</div>;
}

function getActiveLevel(currentLevel: number, navigateTTNL: (level: number, set?: boolean) => void, isCongrats: boolean, isTransitioning: eTransition, dictLevel: { [id: string]: LevelData }, level: number) {
    if (level > 24 && level > currentLevel) {
        return <Locked currentLevel={level} />;
    }

    if (isCongrats) {
        return <Congrats className={isTransitioning === eTransition.out ? "transition-fadeout" : "transition-fadein"} currentLevel={level} />
    }

    const setNextLevel = (set?: boolean) => {
        navigateTTNL(level + 1, set);
    };

    return getLevelComponent(level, setNextLevel, isTransitioning, dictLevel);
}

function getLevelComponent(currentLevel: number, setNextLevel: (set?: boolean) => void, isTransitioning: eTransition, dictLevel: { [id: string]: LevelData }) {
    if ("0" in dictLevel) {
        return <></>;
    }

    if (currentLevel.toString() in dictLevel) {
        var levelData = dictLevel[currentLevel.toString()];
        return <div className={isTransitioning === eTransition.out ? "transition-fadeout" : "transition-fadein"} ><DefaultLevel levelData={levelData} currentLevel={currentLevel} setNextLevel={setNextLevel} /></div>;
    }

    return <Locked currentLevel={currentLevel} />;
}

export default ActiveLevel;