import { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { getRandomEnumMember } from '../hooks/RandomGenerator';

export enum PairPuzzleStyle {
    Animals = 0,
    Tools = 1,
    Fruits = 2
}

function generateRandomArray() {
    var dim = 6;
    var arrRandom = Array.from({ length: dim }, () => Array.from({ length: dim }, () => 0));
    var arrAllElements = [];
    for (var i = 0; i < dim * dim; i += 2) {
        var value = Math.floor(Math.random() * 10);
        arrAllElements.push(value);
        arrAllElements.push(value);
    }

    arrAllElements = arrAllElements.sort(x => Math.random() - 0.5);
    for (var x = 0; x < dim; x++) {
        for (var y = 0; y < dim; y++) {
            arrRandom[x][y] = arrAllElements[x * dim + y];
        }
    }

    return arrRandom;
}

function PairPuzzle(props: { setNextLevel: Function }) {
    const [stylePair] = useState(getRandomEnumMember(PairPuzzleStyle));
    const [arrayValues] = useState(generateRandomArray());
    const [arrayStates, setStates] = useState(Array.from({ length: arrayValues.length }, () => Array.from({ length: arrayValues.length }, () => 0)));
    const [prev, setPrev] = useState({ x: -1, y: -1 });
    const [current, setCurrent] = useState({ x: -1, y: -1 });
    const returnStyleFromState = (randomStyle: PairPuzzleStyle) => {
        switch (randomStyle) {
            case PairPuzzleStyle.Tools:
                return "img-tools";
            case PairPuzzleStyle.Fruits:
                return "img-fruits";
            default:
                return "img-animals";
        }
    };
    const validatePair = (event: React.MouseEvent, x: number, y: number) => {
        event.preventDefault();
        if (prev.x === -1 && prev.y === -1) {
            setPrev({ x, y });
            return;
        }

        setCurrent({ x, y });
        if (arrayValues[prev.x][prev.y] === arrayValues[x][y]) {
            var statesCopy = [...arrayStates];
            statesCopy[prev.x][prev.y] = 1;
            statesCopy[x][y] = 1;
            setStates(statesCopy);
            var dimCopy = statesCopy.length;
            if (statesCopy.reduce((r, x) => r + x.reduce((s, y) => s + y, 0), 0) === dimCopy * dimCopy) {
                props.setNextLevel();
            }
        }

        setTimeout(() => {
            setPrev({ x: -1, y: -1 });
            setCurrent({ x: -1, y: -1 });
        }, 1000);
        return;
    };

    return ( 
        <div>
            <Container fluid >
                <Row>
                    <Col>
                        <table className="m-auto">
                            <tbody>
                            {arrayValues.map((items, index) => {
                                return (
                                    <tr key={"parent-" + index}>
                                        {items.map((subItems, sIndex) => {
                                            return <td key={"item-" + index + "-" + sIndex}
                                                        className="img-box"
                                                onClick={(e) => { arrayStates[index][sIndex] !== 1 && (prev.x !== index || prev.y !== sIndex) && (current.x === -1 && current.y === -1) && validatePair(e, index, sIndex) }}>
                                                <div className={ returnStyleFromState(stylePair) + " img img-" + subItems + (arrayStates[index][sIndex] === 1 || (prev.x === index && prev.y === sIndex) || (current.x === index && current.y === sIndex) ? " locked" : "")}>
                                                </div>
                                            </td>;
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default PairPuzzle;
