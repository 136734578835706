import { useState } from 'react';
import '../css/puzzle.css';
import DragPuzzle from './DragPuzzle';
import NonogramPuzzle from './NonogramPuzzle';
import PairPuzzle from './PairPuzzle';
import PermutationPuzzle from "./PermutationPuzzle";
import PuzzleType from './PuzzleType';
import SelectTypePuzzle from './SelectTypePuzzle';
import SudokuPuzzle from './SudokuPuzzle';

function ActivePuzzle() {
    const setNextLevel = () => {
        var copy = [...pickedType];
        setPickedType(copy);
        setId(crypto.randomUUID());
    };
    const [selectedTypes, setSelectedTypes] = useState(new Array<number>());
    const [pickedType, setPickedType] = useState(new Array<number>());
    const [id, setId] = useState(crypto.randomUUID());
    if (selectedTypes.length <= 0) {
        return <div className="main-container"><SelectTypePuzzle setSelectedTypes={setSelectedTypes} /></div>;
    }

    if (pickedType.length === 0) {
        setPickedType(selectedTypes);
    }

    switch (pickedType.pop()) {
        case PuzzleType.Permutation:
            return <div className="main-container"><PermutationPuzzle key={id} setNextLevel={setNextLevel} /></div>;
        case PuzzleType.Memory:
            return <div className="main-container"><PairPuzzle key={id} setNextLevel={setNextLevel} /></div>;
        case PuzzleType.Nonogram:
            return <div className="main-container"><NonogramPuzzle key={id} setNextLevel={setNextLevel} /></div>;
        case PuzzleType.Sudoku:
            return <div className="main-container"><SudokuPuzzle key={id} setNextLevel={setNextLevel} /></div>;
        case PuzzleType.Shuffle:
            return <div className="main-container"><DragPuzzle key={id} setNextLevel={setNextLevel} /></div>;
        default:
            return <></>;
    }
}


export default ActivePuzzle;