import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './css/app.css';
import NavHeader from './header/NavHeader';
import ActiveLevel from './level/ActiveLevel';
import Guide from './guide/Guide';
import ActivePuzzle from './puzzles/ActivePuzzle';

function Root() {
    return (
        <div>
            <header>
                <NavHeader />
            </header>
            <Routes>
                <Route path="/" element={<Guide />} />
                <Route path="/level/:levelId" element={<ActiveLevel />} />
                <Route path="/puzzle" element={<ActivePuzzle />} />
            </Routes>
        </div>
    );
}

function App() {
    return <Router><Root /></Router>;
}

export default App;